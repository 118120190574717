<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-联系人设置
-->
<style lang="less">
</style>

<template>
  <Form>
    <FormItem
      v-for="(item, i) in inputItems"
      :key="i"
      :label="item.label + ':'"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        v-model.trim="model[item.key]"
        :placeholder="item.placeholder"
        class="display-flex-1"
        @input="checkSubmit"
      />
      <div
        v-if="item.key === 'mobile' && needCode"
        style="margin-left: 15px"
      >
        <yg-otp-button
          :button-type="2"
          :disabled="optDisabled"
          :tel="model.mobile"
        />
      </div>
    </FormItem>
  </Form>
</template>

<script>
import { isPhone } from '@/libs/regularUtils';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    needCode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    optDisabled () {
      return !isPhone(this.model.mobile);
    },
    inputItems () {
      if (this.needCode) {
        return [
          { label: '联系人', labelWidth: 120, placeholder: '请输入联系人的姓名', key: 'contact', isRequired: true },
          { label: '联系人手机号', labelWidth: 120, placeholder: '请输入11位手机号', key: 'mobile', isRequired: true },
          { label: '验证码', labelWidth: 120, placeholder: '请输入短信验证码', key: 'code', isRequired: true }
        ];
      }
      return [
        { label: '联系人', labelWidth: 120, placeholder: '请输入联系人的姓名', key: 'contact', isRequired: true },
        { label: '联系人手机号', labelWidth: 120, placeholder: '请输入11位手机号', key: 'mobile', isRequired: true }
      ];
    }
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
