/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 设备商相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class DpApiSet {
  constructor () {
    this.dpList = BaseApi.createModel('获取设备商信息列表', '/opmp/dp/list').setMethod(0);
    this.dpChangeStatus = BaseApi.createModel('启用禁用设备商', '/opmp/dp/changeStatus').setMethod(2);
    this.dpAdd = BaseApi.createModel('新增设备商信息', '/opmp/dp/add');
    this.dpAdminSet = BaseApi.createModel('设置设备商-登录账号', '/opmp/dp/admin/set');
    this.dpConcatSet = BaseApi.createModel('设置设备商-联系人', '/opmp/dp/contact/set');
    this.dpPartnersSet = BaseApi.createModel('设置设备商-合作厂商', '/opmp/dp/partners/add');
    this.dpPartnersList = BaseApi.createModel('设置设备商-合作厂商', '/opmp/dp/partners/get').setMethod(0, false);
    this.dpInfo = BaseApi.createModel('获取小区信息', '/opmp/dp/info').setMethod(0, false);
    // this.dpInfo = BaseApi.createModel('获取小区-业组织信息', '/opmp/dp/org').setMethod(0, false);
  }
}

export default new DpApiSet();
