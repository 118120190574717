<!--
    Created by 程雨喵'mac on 2024/2/5.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：合作厂商
-->
<style lang="less">
.device-dealer-setting-factory {
  .ivu-alert {
    padding: 8px 8px 8px 38px;
    .ivu-alert-icon {
      top: 14px;
    }
  }
}
</style>

<template>
  <div class="device-dealer-setting-factory">
    <Alert show-icon>
      <div
        class="display-flex"
        style="justify-content: space-between;align-items: center"
      >
        <p>请关联设备商的合作生产厂家</p>
        <yg-button
          type="text"
          @click="_clickSetting"
        >
          添加合作厂家
        </yg-button>
      </div>
    </Alert>
    <!--<div class="padding-primary-top">-->
    <!--  <yg-setting-area-->
    <!--    :items="settingItems"-->
    <!--    :total="page.total"-->
    <!--    @on-click="_clickSetting"-->
    <!--  />-->
    <!--</div>-->
    <div class="padding-primary-top">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :page-size-infinite="true"
        :max-height="9999"
      />
    </div>
    <v-factory-choose
      v-if="chooseDialogShow"
      :select-list="tableData"
      @on-sure="onSureChoose"
      @on-close="chooseDialogShow = false"
    />
  </div>
</template>

<script>
import md5 from 'md5';
import DpApiSet from '@/request/api/DpApiSet';
import vFactoryChoose from './factory-choose.vue';

export default {
  components: { vFactoryChoose },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('添加合作厂家', 'add', '')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('partnerName', '合作厂家名称').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('setting', '操作').setWidth(80).setAlign('center').setButtons([
          { title: '移除', name: 'del' }
        ], ({ name }, row, index) => {
          // 移除
          this.tableData.splice(index, 1);
          this.checkSubmit();
        })
      ],
      chooseDialogShow: false,
      // 初始数据md5(用于比较值，是否发生变化)
      orgTableDataMd5: ''
    };
  },
  created () {
    this.$emit('on-change', {
      disabled: true,
      msg: '数据无变化'
    });
    this.requestData();
  },
  methods: {
    checkSubmit () {
      const listMd5 = md5(JSON.stringify(this.tableData));
      this.$emit('on-change', {
        disabled: listMd5 === this.orgTableDataMd5,
        msg: '数据无变化'
      });
    },
    onSureChoose (list) {
      this.tableData = [...this.tableData, ...list];
      this.chooseDialogShow = false;
      this.checkSubmit();
    },
    _clickSetting (key) {
      this.chooseDialogShow = true;
    },
    requestData () {
      const api = DpApiSet.dpPartnersList;
      api.params = {
        deviceProviderId: this.model.id
      };
      this.$http(api).then(res => {
        const list = res.data.rows;
        console.log(list);
        this.tableData = list;
        this.page.total = list.length;
        this.orgTableDataMd5 = md5(JSON.stringify(list));
      });
    },
    requestSubmit () {
      const params = {
        deviceProviderId: this.model.id,
        partners: []
      };
      this.tableData.forEach(v => {
        params.partners.push({
          partnerId: v.partnerId,
          partnerName: v.partnerName
        });
      });
      const api = DpApiSet.dpPartnersSet;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-change', {
          disabled: true,
          msg: '数据无变化'
        });
        this.$emit('on-finish');
        this.requestData();
      });
    }
  }
};
</script>
