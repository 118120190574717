<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：服务商/设备商/物业公司/ 的基本设置
-->
<style lang="less">

</style>

<template>
  <yg-modal
    :title="`新增${infoTypeName}`"
    width="500"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="padding-primary-bottom"
    >
      <Form>
        <FormItem
          v-for="(item, i) in formItems"
          :key="i"
          :label="item.label + ':'"
          :label-width="item.labelWidth"
          :required="item.isRequired"
        >
          <p v-if="item.key === 'parentName'">
            {{ parentName }}
          </p>
          <Input
            v-else-if="item.key === 'providerName' || item.key === 'communityName'"
            v-model.trim="model[item.key]"
            :placeholder="item.placeholder"
            @input="checkSubmit"
          />
          <!--物业公司，省份，城市，小区，点位-->
          <yg-site-cascader
            v-else-if="infoType === 4 && item.key === 'propertyCompanyArry'"
            v-model="model[item.key]"
            :level="5"
            :disabled="item.disabled"
            style="width: 100%"
          />
          <RadioGroup
            v-else-if="infoType !== 4"
            v-model="model.principalType"
            @on-change="checkSubmit"
          >
            <Radio
              v-for="(sub, r) in principalTypeOptions"
              :key="'r-' + r"
              :label="sub.value"
            >
              <span>{{ sub.label }}</span>
            </Radio>
          </RadioGroup>
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>
import { mapActions } from 'vuex';
const infoTypeDesc = {
  1: '服务商',
  2: '设备商',
  3: '物业公司',
  4: '小区'
};
const fatherDesc = {
  1: '平台',
  2: '服务商',
  3: '服务商',
  4: '服务商'
};
export default {
  components: {},
  props: {
    // 基本设置类型- 1：服务商，2：设备商，3：物业公司, 4:小区
    infoType: {
      type: Number,
      default: 1
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      principalTypeOptions: []
    };
  },
  computed: {
    parentName () {
      if (this.infoType === 1) {
        return '智能充电管理平台';
      }
      return this.$storage.merchantInfo.providerName || '-';
    },
    infoTypeName () {
      let str = '';
      switch (this.infoType) {
      case 1:
        str = '服务商';
        break;
      case 2:
        str = '设备商';
        break;
      case 3:
        str = '物业公司';
        break;
      case 4:
        str = '小区';
        break;
      }
      return str;
    },
    formItems () {
      if (this.infoType === 4) {
        return [
          { label: '所属服务商', labelWidth: 145, placeholder: '', key: 'parentName', isRequired: false },
          { label: '所属物业公司', labelWidth: 145, placeholder: '', key: 'propertyCompanyArry', isRequired: true },
          { label: '小区名称', labelWidth: 145, placeholder: '请输入6~40名称，可汉字、数字、字母或符号组合', key: 'communityName', isRequired: true }
        ];
      }
      return [
        { label: '所属', labelWidth: 145, placeholder: '', key: 'parentName', isRequired: false },
        { label: '名称', labelWidth: 145, placeholder: '请输入6~40名称，可汉字、数字、字母或符号组合', key: 'providerName', isRequired: true },
        { label: '主体类型', labelWidth: 145, placeholder: '', key: 'principalType', isRequired: true }
      ];
    }
  },
  created () {
    console.log(this.model);
    if (this.infoType !== 4) {
      this.formItems.forEach((v, i) => {
        if (i !== 0) {
          v.label = infoTypeDesc[this.infoType] + v.label;
        } else {
          v.label = v.label + fatherDesc[this.infoType];
        }
      });
    }
    this.$nextTick(() => {
      this.checkSubmit();
    });
    this.getPrincipalTypeList().then(res => {
      this.principalTypeOptions = JSON.parse(JSON.stringify(res));
    });
  },
  methods: {
    ...mapActions(['getPrincipalTypeList']),
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
