<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：组织管理-设备商管理
-->
<style lang="less">
</style>

<template>
  <section>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div class="padding-primary-top">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：新增(基础信息)-->
    <v-base-info-submit
      v-if="!!dialog.baseInfoModel"
      :model="dialog.baseInfoModel"
      :info-type="2"
      @on-close="dialog.baseInfoModel = null"
      @on-sure="onSureBaseInfo"
    />
    <!--弹窗：设置-->
    <v-setting-submit
      v-if="!!dialog.settingModel"
      :is-step-mod="dialog.settingIsStepMod"
      :index="dialog.settingIndex"
      :model="dialog.settingModel"
      @on-close="onCloseSubmit"
    />
  </section>
</template>

<script>
import DpApiSet from '@/request/api/DpApiSet';
import { DeviceDealerListModel, DeviceDealerDetailModel } from './model/DeviceDealerDataModel';
import ColumnMixin from './mixin/tableColumn';
import vSettingSubmit from './view/setting/index.vue';
import vBaseInfoSubmit from '@/pages/020__organizationManager/_components/baseInfo.vue';
import { mapActions } from 'vuex';

export default {
  components: { vBaseInfoSubmit, vSettingSubmit },
  mixins: [ColumnMixin],
  data () {
    return {
      testLoading: false,
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:deviceProvider:add'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh').setPermission('system:deviceProvider:query')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        baseInfoModel: null,
        settingModel: null,
        settingIsStepMod: false,
        settingIndex: 0
      },
      principalTypeList: []
    };
  },
  created () {
    this.$ygLoading.show();
    Promise.all([this.getPrincipalTypeList()]).then(res => {
      this.principalTypeList = res[0];
      this.$ygLoading.close();
      this.requestData();
    });
  },
  methods: {
    ...mapActions(['getPrincipalTypeList']),
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    // 新增数据
    onSureBaseInfo () {
      const res = this.dialog.baseInfoModel.checkValidAdditionSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAdd(res.params);
      }
    },
    onCloseSubmit (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.settingIsStepMod = false;
      this.dialog.settingModel = null;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.baseInfoModel = DeviceDealerDetailModel.createAdditionModel();
        break;
      case 'refresh':
        this.onChangePage(1);
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DpApiSet.dpList;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        resData.rows.map(v => {
          v.principalType = v.principalType + '';
          v.principalTypeList = this.principalTypeList;
        });
        this.tableData = DeviceDealerListModel.initListWithDataList(resData.rows);
      });
      // const resList = ServiceDealerListModel.createFakeDataList();
      // console.log(resList);
      // this.page.total = resList.length;
      // this.tableData = ServiceDealerListModel.initListWithDataList(resList);
    },
    requestAdd (p) {
      const api = DpApiSet.dpAdd;
      api.params = p;
      this.$http(api).then(res => {
        this.dialog.baseInfoModel = null;
        this.$Message.success('操作成功');
        this.onChangePage(1);
        this.$store.dispatch('checkPermission', [
          'system:deviceProvider:admin:set',
          'system:deviceProvider:contact:set',
          'system:deviceProvider:partner:add'
        ]).then(hasPermission => {
          this.dialog.settingIsStepMod = hasPermission;
          if (hasPermission) {
            this.requestDetail(res.data.id);
            // this.dialog.settingModel = DeviceDealerDetailModel.initModelWithData(res.data);
          }
        });
      });
    },
    requestChangeStatus (row, status) {
      const api = DpApiSet.dpChangeStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        row.status = status ? 1 : 0;
        row.h5StatusSwitch = status;
      }).catch(e => {
        row.status = status ? 0 : 1;
        row.h5StatusSwitch = !status;
      });
    },
    requestDetail (id) {
      const api = DpApiSet.dpInfo;
      api.params = { id };
      this.$http(api).then(res => {
        this.dialog.settingModel = DeviceDealerDetailModel.initModelWithData(res.data);
      });
      // this.dialog.settingModel = row;
      // const resData = DemoDetailModel.createFakeData();
      // this.dialog.detailModel = DemoDetailModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
