<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.device-dealer-setting-dialog {
  padding-bottom: @containerGap;
  height: 100%;
  .setting-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .setting-inner-header {
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
      box-sizing: border-box;
      line-height: 17px;
      background: @backColorNormal;
      border-radius: @borderRadiusMid;
      .setting-inner-header-row {
        .flex-grow(50%);
        &:nth-of-type(n+3) {
          padding-top: @containerGap * 0.5;
        }
      }
    }
    .setting-inner-box {
      flex: 1;
      margin-top: @containerGap;
      overflow: auto;
      .step-outer {
        padding-top: @containerGap;
        background-color: @backColorStrong;
        border-radius: @borderRadiusMin @borderRadiusMin 0 0;
      }
    }
    .setting-content-box {
      background-color: @backColorStrong;
      border-radius: 0 0 @borderRadiusMin @borderRadiusMin;
    }
  }
}
</style>

<template>
  <yg-modal
    width="540"
    :title="modalTitle"
    :back-type="2"
    :sure-text="sureText"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="device-dealer-setting-dialog"
    >
      <div class="setting-inner">
        <div class="setting-inner-header">
          <div
            v-for="(item, i) in topInfos"
            :key="i"
            class="setting-inner-header-row"
          >
            <yg-attribute-key-value
              :label="item.label"
              :label-width="120"
              :max-height="18 * 3"
              :value="model[item.key]"
              :can-copy="item.canCopy"
              :can-tooltip="!!item.canTooltip"
            />
          </div>
        </div>
        <div class="setting-inner-box">
          <div
            v-if="isStepMod"
            class="step-outer"
          >
            <yg-steps
              :current-index="tab.index"
              :items="tab.dicItems"
              prev-text=""
              next-text=""
              @on-click="onStepChanged"
            />
          </div>
          <yg-tab-primary
            v-else
            :index="tab.index"
            :items="tab.items"
            :permission-items="tab.permissionItems"
            :type="2"
            @on-change="onTabChanged"
          />
          <div class="padding-primary setting-content-box">
            <v-account
              v-if="tab.index === 0"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-contact
              v-else-if="tab.index === 1"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-factory
              v-else-if="tab.index === 2"
              ref="vFactory"
              :model="model"
              @on-change="onChangeDisabled"
              @on-finish="submitFinish"
            />
          </div>
        </div>
      </div>
    </div>
  </yg-modal>
</template>

<script>
import DpApiSet from '@/request/api/DpApiSet';
import vAccount from '@/pages/020__organizationManager/_components/account.vue';
import vContact from '@/pages/020__organizationManager/_components/contact.vue';
import vFactory from './factory.vue';
import stepMixin from '@/pages/020__organizationManager/_mixin/stepMixin';
export default {
  components: { vAccount, vContact, vFactory },
  mixins: [stepMixin],
  props: {
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      topInfos: [
        { label: '设备商名称：', key: 'deviceProviderName', canCopy: false, canTooltip: true },
        { label: '设备商主体类型：', key: 'principalTypeName', canCopy: false, canTooltip: true },
        { label: '设备商编码：', key: 'deviceProviderCode', canCopy: false, canTooltip: true },
        { label: '所属服务商：', key: 'serviceProviderName', canCopy: false, canTooltip: true }
      ],
      tab: {
        index: 0,
        items: ['登录账号设置', '联系人设置', '合作厂商设置'],
        permissionItems: ['system:deviceProvider:admin:set', 'system:deviceProvider:contact:set', 'system:deviceProvider:partner:add'],
        dicItems: [
          { title: '步骤1', info: '登录账号设置' },
          { title: '步骤2', info: '联系人设置' },
          { title: '步骤3', info: '合作厂商设置' }
        ]
      },
      buttonDisabled: true,
      buttonDisabledMsg: '',
      needRefreshList: false
    };
  },
  created () {
    this.tab.index = this.index;
  },
  methods: {
    onSure () {
      let res = {};
      const tIndex = this.tab.index;
      if (tIndex === 0) {
        // 登录账号设置
        res = this.model.checkValidAccountSubmit();
      } else if (tIndex === 1) {
        // 联系人设置
        res = this.model.checkValidConcatSubmit();
      } else if (tIndex === 2) {
        // 合作厂商设置
        this.$refs.vFactory.requestSubmit();
        return;
      }
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestSubmit(tIndex, res.params);
      }
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    },
    onChangeDisabled ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.buttonDisabled = false;
      this.buttonDisabledMsg = '';
      this.tab.index = e;
    },
    requestSubmit (type, params) {
      let api = {};
      if (type === 0) {
        // 登录账号
        api = DpApiSet.dpAdminSet;
      } else if (type === 1) {
        // 联系人
        api = DpApiSet.dpConcatSet;
      } else if (type === 2) {
        api = DpApiSet.dpPartnersSet;
      }
      api.params = params;
      this.$http(api).then(res => {
        this.needRefreshList = type === 0 || type === 1;
        if (type === 0) {
          // 提交后就不可编辑了
          this.model.h5AdminCanChange = false;
        } else if (type === 0) {
          this.model.code = '';
        }
        this.onChangeDisabled({ disabled: true, msg: '无数据变更' });
        this.$Message.success('操作成功');
        this.submitFinish();
      });
    }
  }
};
</script>
