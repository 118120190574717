<!--
    Created by 程雨喵'mac on 2024/2/5.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：合作厂商-选择
-->
<style lang="less">

</style>

<template>
  <yg-drawer
    title="添加合作厂家"
    width="560"
    :sure-button-disabled="!currentSelectList.length"
    sure-button-disabled-text="未勾选任何厂商"
    @on-close="onClose"
    @on-sure="onSure"
  >
    <div slot="content">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :page-size-infinite="true"
        :max-height="9999"
        @on-selection="onChangeSelection"
      />
    </div>
  </yg-drawer>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  components: {},
  props: {
    // 当前已选的列表
    selectList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('partnerName', '厂家名称').setSpan({ canTooltip: true })
      ],
      currentSelectList: []
    };
  },
  created () {
    this.getFactoryList();
  },
  methods: {
    ...mapActions(['getFactoryTypeList']),
    onClose () {
      this.$emit('on-close');
    },
    onSure () {
      this.$emit('on-sure', this.currentSelectList);
    },
    onChangeSelection (list) {
      this.currentSelectList = list;
    },
    getFactoryList () {
      this.getFactoryTypeList().then(resList => {
        const tableData = [];
        resList.forEach(v => {
          tableData.push({
            partnerName: v.label,
            value: v.value,
            partnerId: v.id,
            _checked: false,
            _disabled: !!this.selectList.find(s => s.partnerId === v.id)
          });
        });
        this.tableData = tableData;
        this.page.total = tableData.length;
      });
    }
  }
};
</script>
