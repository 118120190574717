<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-登录账号设置
-->
<style lang="less">
</style>

<template>
  <Form v-if="model.h5AdminCanChange">
    <FormItem
      v-for="(item, i) in inputItems"
      :key="i"
      :label="item.label + ':'"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        v-model.trim="model[item.key]"
        :type="item.type"
        :placeholder="item.placeholder"
        @input="checkSubmit"
      />
    </FormItem>
  </Form>
  <Form v-else>
    <FormItem
      v-for="(item, i) in inputItems"
      :key="i"
      :label="item.label + ':'"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        :value="item.key === 'h5AdminUserName' ? model.h5AdminUserName : '已设置'"
        :disabled="true"
        :placeholder="item.placeholder"
        @input="checkSubmit"
      />
    </FormItem>
  </Form>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      inputItems: [
        { label: '登录账号', labelWidth: 90, placeholder: '请输入小于20位的账号信息，支持中文\\数字\\字母及().', key: 'h5AdminUserName', type: 'text', isRequired: true },
        { label: '登录密码', labelWidth: 90, placeholder: '请输入6~8位密码，必须为数字字母组合', key: 'password', type: 'password', isRequired: true },
        { label: '确认密码', labelWidth: 90, placeholder: '请输入6~8位密码，必须为数字字母组合', key: 'h5PasswordAgain', type: 'password', isRequired: true }
      ]
    };
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      if (this.model.h5AdminCanChange) {
        const item = this.inputItems.find(v => !this.model[v.key]);
        this.$emit('on-change', {
          disabled: !!item,
          msg: item ? (item.label + '不可为空') : ''
        });
      } else {
        this.$emit('on-change', {
          disabled: true,
          msg: '登录账号无法重复设置'
        });
      }
    }
  }
};
</script>
