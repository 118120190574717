export default {
  props: {
    // 是否是步骤
    isStepMod: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sureText () {
      return this.isStepMod ? '下一步' : '提交';
    },
    modalTitle () {
      return this.isStepMod ? '请完善设置' : '设置';
    }
  },
  methods: {
    // 点击下一步
    onStepChanged () {
      this.onTabChanged(this.tab.index + 1);
    },
    submitFinish () {
      if (!this.isStepMod) return;
      if (this.tab.index === (this.tab.items.length - 1)) {
        this.$emit('on-close', true);
      } else {
        this.onStepChanged();
      }
    }
  }
};
