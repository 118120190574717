import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isAlphanumeric, isNumber, isPhone, isValidString1 } from '@/libs/regularUtils';
import md5 from 'md5';

export class DeviceDealerListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.deviceProviderName = ''; // 设备商名称
    this.deviceProviderCode = ''; // 设备商编码
    this.principalType = ''; // 设备商主体类型id
    this.principalTypeName = ''; // 设备商主体类型
    this.serviceProviderName = ''; // 所属服务商
    this.aaa = ''; // 设备商登录账号
    this.serviceProviderId = ''; // 所属服务商id
    this.adminUser = null; // 登录账号对象
    this.contact = ''; // 联系人
    this.mobile = ''; // 联系人电话
    // this.communityCount = 0; // 小区数量
    // this.siteCount = 0; // 点位数量
    // this.deviceCount = 0; // 充电桩数量
    this.status = ''; // 状态 0: 停用，1：启用
    this.createTime = ''; // 创建时间
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5AdminUserName = ''; // 服务商管理员登录账号
    this.h5AdminCanChange = true; // 只有没有设置过登录账号时，才可以设置
  }

  initData (resData) {
    super.initData(resData);
    console.log(resData.principalTypeList);
    this.principalTypeName = ((resData.principalTypeList || []).find(v => v.status && v.value === resData.principalType) || {}).label;
    this.h5StatusSwitch = !!this.status;
    this.h5AdminUserName = resData.adminUser ? resData.adminUser.username : '';
    this.h5AdminCanChange = !this.h5AdminUserName;
    return this;
  }
}
export class DeviceDealerDetailModel extends DeviceDealerListModel {
  constructor () {
    super();
    this.password = ''; // 登录密码(编辑账号时使用)
    this.settleAccount = null; // 结算账户对象 (PropertyCompanySettleAccountModel)
    this.ratioConfig = null; // 公司费率对象 (PropertyCompanySettleAccountModel)
    this.h5PasswordAgain = ''; // 确认密码时使用
  }

  initData (resData) {
    super.initData(resData);
    this.principalTypeName = resData.principalTypeName;
    return this;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    // 专项校验
    if (this.providerName.length < 6 || this.providerName.length > 40) {
      return BaseModel.getValidRes(false, '请输入6-40位设备商的名称');
    } else if (!isValidString1(this.providerName)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    return BaseModel.getValidRes(true, '', {
      providerName: this.providerName,
      principalType: this.principalType,
      ownerId: 1 // 默认填写1
    });
  }

  /**
   * 数据具体校验-账号设置
   * @returns {*}
   */
  checkValidAccountSubmit () {
    console.log(this);
    // 专项校验
    if (this.h5AdminUserName.length < 6 || this.h5AdminUserName.length > 20) {
      return BaseModel.getValidRes(false, '请输入6-20位登录账号');
    } else if (isNumber(this.h5AdminUserName)) {
      return BaseModel.getValidRes(false, '账号不可纯数字');
    } else if (!isAlphanumeric(this.password)) {
      return BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
    } else if (this.password !== this.h5PasswordAgain) {
      return BaseModel.getValidRes(false, '两次输入的密码不同，请重新输入');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      adminUserName: this.h5AdminUserName,
      password: md5(this.password)
    });
  }

  /**
   * 数据具体校验-联系人
   * @returns {*}
   */
  checkValidConcatSubmit () {
    // 专项校验
    if (!isPhone(this.mobile)) {
      return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
    } else if (this.contact.length < 1 || this.contact.length > 20) {
      return BaseModel.getValidRes(false, '联系人请输入1-20位');
    } else if (!isValidString1(this.contact)) {
      return BaseModel.getValidRes(false, '联系人请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      contact: this.contact,
      mobile: this.mobile,
      captcha: this.code,
      uuid: ''
    });
  }

  static createFakeData () {
    return this.createFakeDataList(1)[0];
  }

  static createAdditionModel () {
    return new this();
  }
}
